<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> /
          <router-link to="/accounts">Project</router-link>
        </h1>
      </v-flex>
      <v-flex xs12 md12 lg6>
        <h2 class="f-alfa primary--text mt-5">{{ project.name }}</h2>
        <p class="accent--text">
          <v-icon color="accent">mdi-map-marker</v-icon>{{ project.location }},
          <v-icon class="ml-5" color="accent">mdi-account-outline</v-icon
          >{{ project.customer }}
        </p>
        <p>{{ project.description }}</p>
        <h3 class="mt-5 mb-2 accent--text">Create a Post</h3>
        <v-textarea
          single-line
          outlined
          v-model="content"
          style="background-color: #000; border: #212121"
          label=""
        ></v-textarea>
        <div class="d-flex mt-2 justify-space-between">
          <div>
            <v-icon color="accent" @click="handleFileImport">mdi-camera</v-icon>
            <v-icon class="ml-5" color="accent">mdi-attachment</v-icon>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onFileChanged"
            />
            <div class="mt-2">
              <img :src="imageSrc" style="max-width: 150px" />
            </div>

            <p
              v-if="fileName"
              style="background-color: #212121"
              class="pa-2 mt-2"
            >
              {{ getFileName }} <v-icon>mdi-close</v-icon>
            </p>
          </div>
          <div>
            <v-btn class="primary" @click="post" v-if="!loading">Post</v-btn>
            <v-btn class="primary" @click="post" v-else><v-progress-circular indeterminate></v-progress-circular></v-btn>
          </div>
        </div>
        <div>
          <div v-for="item in notes" :key="item._id" class="pa-3 mt-3" style="background-color: #212121;border-radius: 8px;">
            <div class="d-flex align-center mb-3">
              <img :src="item.added_by.display_picture" style="width: 48px;height: 48px;border-radius: 24px;"/>
              <div class="ml-3">
                <p class="ma-0"><b>{{item.added_by.name}}</b></p>
                <p class="ma-0 caption">{{ item.created | moment('MMMM Do YYYY, h:mm:ss a') }}</p>
              </div>
            </div>
            <p>{{ item.content }}</p>
            <img :src="item.attachment" style="width: 100%;"/>
          </div>
        </div>
      </v-flex>
      <v-flex lg2></v-flex>
      <v-flex md12 lg4 class="">
        <div>
          <div class="d-flex">
            <h2 class="accent--text">Team</h2>
            <v-icon class="ml-5 primary--text" style="cursor: pointer;" @click="addTeam">mdi-plus-circle</v-icon>
          </div>
          
          <div v-for="item in project.team" :key="item._id" class="d-flex mt-4">
            <img :src="item.display_picture" alt="" style="width: 48px;height:48px;border-radius: 24px;" v-if="item.display_picture">
            <div style="width: 48px;height: 48px;background-color: #212121; border-radius: 24px;" v-else></div>
            <div class="pl-3">
              <p class="pa-0 ma-0">{{ item.name }}</p>
              <p class="pa-0 ma-0 accent--text">{{ item.department.name }}</p>
            </div>
          </div>
        </div>
        <div class="d-flex mt-10">
            <h2 class="accent--text">Tasks</h2>
            <v-icon class="ml-5 primary--text" style="cursor: pointer;" @click="addTask">mdi-plus-circle</v-icon>
          </div>
        <p class="mt-5">Total Tasks  - 0</p>
        <p>Pending Tasks - 0</p>
        <p>Tasks Completed - 0</p>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import { BASE_URL } from "@/config";
import Axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      content : "",
      project: {},
      posts: [],
      files: [],
      fileName: "",
      imageSrc: null,
      notes : [],
      loading : false
    };
  },
  mounted() {
    this.fetchProject();
    this.fetchProjectNotes();
  },
  computed: {
    ...mapGetters(['USER']),
    getFileName() {
      return (
        this.fileName.substring(0, 12) +
        "......." +
        this.fileName.substring(this.fileName.length - 10)
      );
    },
  },
  methods: {
    async fetchProject() {
      let url = BASE_URL + "/project/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.project = data;
    },
    async fetchProjectNotes() {
      let url = BASE_URL + "/project/note/"+this.$route.params.id;
      let {data} = await Axios.get(url)
      this.notes = data
    },
    edit() {
      this.$router.push({
        name: "EditProject",
        params: { id: this.$route.params.id },
      });
    },
    addTask(){
      this.$router.push('/add-task?project='+this.$route.params.id)
    },
    handleFileImport() {
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.files = e.target.files;
      this.fileName = this.files[0].name;
      this.convertImageToDataURL();
    },
    convertImageToDataURL() {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(this.files[0]);
    },
    async post(){
      this.loading = true
      let url = BASE_URL + '/project/note/'+ this.$route.params.id
      let formData = new FormData();
        formData.append("type", "Image");
        formData.append("content",this.content)
      if(this.files[0]){
        formData.append("photos",this.files[0])
      }
      const headers = {
        'Authorization' : this.USER.token
      }
      let { data } = await Axios.post(url, formData, {headers});
      this.content = ""
      this.fileName = ""
      this.imageSrc = ""
      this.files = []
      this.loading = false
      this.fetchProjectNotes()
    },
    addTeam(){
      this.$router.push({name : 'AddTeam',params : {project : this.$route.params.id}})
    }
  },
};
</script>
  
    <style scoped>
.fs-12 {
  font-size: 12pt !important;
  font-weight: 400 !important;
}
.image-container {
  max-width: 100%;
  height: 200px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}
</style>